import React, { useState, useEffect, useRef } from "react";
import "./style.css";

function App() {
  const [image, setImage] = useState(null); // User's uploaded image
  const [overlay, setOverlay] = useState(null); // Selected overlay
  const [funMode, setFunMode] = useState(false); // Toggle for Fun Mode
  const canvasRef = useRef(null); // Reference to the canvas element

  // Handle image upload
  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImage(URL.createObjectURL(file)); // Convert file to a URL
    }
  };

  // Handle overlay selection
  const handleOverlaySelect = (overlayName) => {
    setOverlay(`/overlays/${overlayName}.png`); // Set the selected overlay path
  };

  // Draw the image and overlay on the canvas
  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");

    if (image) {
      const baseImage = new Image();
      baseImage.src = image;

      baseImage.onload = () => {
        // Clear the canvas
        ctx.clearRect(0, 0, canvas.width, canvas.height);

        // Draw the base image
        ctx.drawImage(baseImage, 0, 0, canvas.width, canvas.height);

        // If an overlay is selected, draw it on top
        if (overlay) {
          const overlayImage = new Image();
          overlayImage.src = overlay;

          overlayImage.onload = () => {
            ctx.drawImage(overlayImage, 0, 0, canvas.width, canvas.height);
          };
        }
      };
    }
  }, [image, overlay]); // Re-run whenever the image or overlay changes

  // Handle Fun Mode toggle
  const toggleFunMode = () => {
    setFunMode(!funMode);

    if (!funMode) {
      startRainEffect();
    } else {
      stopRainEffect();
    }
  };

  // Start raining PNGs
  const startRainEffect = () => {
    const rainContainer = document.getElementById("rain-container");
    for (let i = 0; i < 50; i++) {
      const rainDrop = document.createElement("img");
      rainDrop.src = "/overlays/rain.png"; // Use a different PNG for rain effect
      rainDrop.className = "rain-drop";
      rainDrop.style.left = `${Math.random() * 100}vw`;
      rainDrop.style.animationDelay = `${Math.random() * 2}s`;
      rainContainer.appendChild(rainDrop);
    }
  };

  // Stop raining PNGs
  const stopRainEffect = () => {
    const rainContainer = document.getElementById("rain-container");
    rainContainer.innerHTML = ""; // Remove all rain drops
  };

  // Handle download
  const downloadImage = () => {
    const canvas = canvasRef.current;
    const link = document.createElement("a");
    link.download = "kappy-pfp-picture.png";
    link.href = canvas.toDataURL();
    link.click();
  };

  /*const shareOnTwitter = () => {
    const tweetText = encodeURIComponent("Check out my custom profile picture created using this awesome app!");
    const tweetUrl = encodeURIComponent(window.location.href); // Optional: URL to promote
    const twitterUrl = `https://twitter.com/intent/tweet?text=${tweetText}&url=${tweetUrl}`;
    window.open(twitterUrl, "_blank");
  };
  <button className="custom-button twitter-button" onClick={shareOnTwitter}>
  Share on X
</button>*/
  
  return (
    <div className="app">
      <header>
        <h1>{funMode ? "Where did they all come from??" : "Kappy PFP Customizer"}</h1>
       <a>Community Built by @DifficultNFTs</a><br></br>
        <a
            href="https://x.com/DifficultNFTs" // Replace with your actual social account link
            target="_blank"
            rel="noopener noreferrer"
            className="social-link"
        >
            Ideas or issues? Let me know on X.
        </a>
      </header>
      <button className="fun-mode-button" onClick={toggleFunMode}>
          Toggle Fun Mode
        </button>
      <div className="upload-section">
        <input
          id="file-upload"
          type="file"
          accept="image/*"
          onChange={handleImageUpload}
          hidden
        />
        <label htmlFor="file-upload" className="custom-button">
          Upload Image
        </label>
        <div className="options">
        <button className="custom-button" onClick={() => handleOverlaySelect("Photobomb-Kappy")}>
            Photobomb Kappy
          </button>
        <button className="custom-button" onClick={() => handleOverlaySelect("Kappy-Facing")}>
            Kappy Takeover
        </button>
        </div>
      </div>
      <div className="preview">
        <canvas ref={canvasRef} width={500} height={500}></canvas>
      </div>
      <div className="options">
        <br></br>
        {image && (
        <button className="custom-button download-btn" onClick={downloadImage}>
            Download PFP
        </button>
        )}
      </div>

      <div id="rain-container"></div>
      <footer>
        <a><img src="/overlays/footer.png" alt="Footer Decoration" className="bouncing-footer" /></a>
        <br></br>
        <br></br>
    </footer>

    </div>
  );
}

export default App;
